import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const Card = ({ item }) => {
  const {
    id,
    images,
    title,
    bedrooms,
    bathrooms,
    description: { description },
    listPrice,
  } = item;

  const cardImage = getImage(images[0]);

  return (
    <Container>
      <div key={id} className="card">
        <div className="img-container">
          <Link to={'/'}>
            <GatsbyImage image={cardImage} className="card-img" alt={title} />
          </Link>
        </div>
        <div className="card-content">
          <Link to="/">
            <h1 className="card-title">{title}</h1>
          </Link>
          <div className="card-subtitle">
            <h3 className="bedrooms">{bedrooms} beds</h3>
            <h3 className="bathrooms">{bathrooms} baths</h3>
          </div>
          <p className="card-description">{description}</p>
          <h4 className="price">{listPrice}</h4>
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 4rem;

  .card {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .img-container {
    width: 100%;
  }
  .card-img {
    width: 100%;
  }
  .card-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .card-title {
    text-transform: uppercase;
  }
  .card-subtitle {
    display: flex;
    padding: 0 0 0.5rem;
  }
  .bedrooms,
  .bathrooms {
    font-size: 1.5rem;
    font-weight: 500;
  }
  .bedrooms {
    padding: 1rem 0.5rem 0 0;
  }
  .bathrooms {
    padding: 1rem 0 0 0.5rem;
  }
  .card-description {
    padding-bottom: 2rem;
  }

  @media (min-width: 800px) {
    .card {
      flex-direction: row;
      gap: 4rem;
    }
    .card-subtitle {
      display: flex;
      padding: 1rem 0 0.5rem;
    }
    .card-description {
      width: 70%;
    }
  }
`;

export default Card;
