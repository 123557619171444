import React from 'react';
import Title from '../../components/Title';
import CardFlipped from '../../components/CardFlipped';
import Card from '../../components/Card';
import styled from 'styled-components';

const ForSale = ({ forsale = [] }) => {
  const featured = forsale.filter((property) => property.featured);

  return (
    <Container>
      <Title title="properties for sale" />
      <div className="section-center">
        {featured.map((item, index) => {
          return index % 2 === 0 ? (
            <Card item={item} key={index} />
          ) : (
            <CardFlipped item={item} key={index} />
          );
        })}
      </div>
    </Container>
  );
};

const Container = styled.div`
  padding-bottom: 2.5rem;
  .section-center {
    flex-direction: column;
  }
`;

export default ForSale;
